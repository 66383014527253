var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('div',{staticStyle:{"background-color":"#fff","padding":"20px 20px","border-radius":"10px"}},[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"12","cols":"12"}},[_c('h2',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(this.$route.params.name_en)+" ")]),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)],1),_c('div',{staticStyle:{"margin-top":"20px"}}),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"href":_vm.items.href},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-row',[_c('v-divider',{staticClass:"ma-2 mt-8 mb-5",attrs:{"color":"#e9e9e9"}}),_c('v-col',{staticClass:"py-0",attrs:{"sm":"12","cols":"12"}},[[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"mobile-breakpoint":0,"items":_vm.listSession,"search":_vm.search,"items-per-page":25,"footer-props":{
                        'items-per-page-options': [10, 25, 50, -1],
                      },"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("no")))]),_c('th',[_vm._v(_vm._s(_vm.$t("name_en")))]),_c('th',[_vm._v(_vm._s(_vm.$t("name_kh")))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("Action")))])])])]}},{key:"body",fn:function(ref){
                      var items = ref.items;
return [(items.length > 0)?_c('tbody',{staticStyle:{"white-space":"nowrap"}},_vm._l((items),function(sess,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(1 + index++))]),_c('td',[_vm._v(_vm._s(sess.name_en))]),_c('td',[_vm._v(_vm._s(sess.name_kh))]),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"depressed":"","small":"","color":"teal","dark":""},on:{"click":function($event){return _vm.$router.push({
                                    name: 'SubjectStudent',
                                    params: {
                                      id: sess.session_id,
                                      name_en: sess.name_en,
                                      student_id: _vm.stu_id,
                                    },
                                  })}}},[_vm._v(" VIEW ")])],1)])}),0):_c('tbody',[_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length}},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" No data available in table ")])],1)])])]}}])})],1)]],2)],1)],1)],1)])],1)],1),_c('div',[_c('div',{staticClass:"loading"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":_vm.fullPage,"opacity":0.9,"background-color":"#dedede","width":40,"height":40},on:{"update:active":function($event){_vm.isLoading=$event}}},[(_vm.myLoading)?_c('div',[_c('img',{attrs:{"width":"100%","src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"}})]):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }