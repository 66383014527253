<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0">
                    {{ this.$route.params.name_en,}}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>
              </v-row>
              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items" :href="items.href">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>

              <v-row>
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-col sm="4" cols="12">
                          <v-text-field
                            outlined
                            append-icon="search"
                            label="Search"
                            v-model="search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :mobile-breakpoint="0"
                        :items="listSession"
                        :search="search"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [10, 25, 50, -1],
                        }"
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>{{ $t("no") }}</th>
                              <th>{{ $t("name_en") }}</th>
                              <th>{{ $t("name_kh") }}</th>

                              <th class="text-center">{{ $t("Action") }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody
                            style="white-space: nowrap"
                            v-if="items.length > 0"
                          >
                            <tr
                              v-for="(sess, index) in items"
                              v-bind:key="index"
                            >
                              <td>{{ 1 + index++ }}</td>
                              <td>{{ sess.name_en }}</td>
                              <td>{{ sess.name_kh }}</td>

                              <td class="text-center">
                                <v-btn
                                  @click="
                                    $router.push({
                                      name: 'SubjectStudent',
                                      params: {
                                        id: sess.session_id,
                                        name_en: sess.name_en,
                                        student_id: stu_id,
                                      },
                                    })
                                  "
                                  depressed
                                  small
                                  class="mx-2"
                                  color="teal"
                                  dark
                                >
                                  VIEW
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                :colspan="headers.length"
                                style="text-align: center"
                              >
                                <v-alert dense outlined type="error">
                                  No data available in table
                                </v-alert>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { getSessionByStudent } from "@schoolbase/web-client-lib";
import store from "@/store";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      search: "",
      token: store.getters.getToken,
      headers: [
        { text: "No", value: "no" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "Major ID", value: "major_id" },
        { text: "Major Generation ID", value: "major_generation_id" },
        { text: "Academic ID", value: "academic_id" },
      ],
      headers1: [
        { text: "Student ID", value: "student_id" },
        { text: "English Name", value: "name_en" },
      ],
      stu_id: this.$route.params.student_id,
      isLoading: false,
      myLoading: false,

      snackbar: {
        show: false,
        message: null,
        color: null,
      },

      items: [
        {
          text: "Major",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Session",
          disabled: true,
        },
      ],

      listSession: [],
      dialog: false,
    };
  },
  components: {
    Loading,
  },
  computed: {},
  methods: {
    async loadSessions() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getSessionByStudent(
          this.$route.params.student_id,
          this.$route.params.major_id,
          this.token
        );

        this.listSession = APIResponse.payload.sort(function (a, b) {
          var nameA = a.name_en;
          var nameB = b.name_en;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },

  async mounted() {
    this.loadSessions();
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}

.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.v-menu__content {
  top: 141px !important;
  left: 1098px !important;
}

.v-menu__content .v-list .v-list-item {
  min-height: 35px !important;
}
.btn_create_new {
  color: #000000 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}

@media (max-width: 576px) {
}
</style>
